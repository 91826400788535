<template>
    <div id="my-news">
        <div class="my-news-list">
            <div class="my-news-item status0">
                <div class="row">
                    <span class="category">#妇科</span>
                    <span class="title">天灸三伏貼增強免疫力 攜手對抗新冠肺炎</span>
                </div>
                <div class="row">
                    <div class="tags">
                        <span class="tag">產婦</span>
                        <span class="tag">PCOS</span>
                    </div>
                    <div class="time">2020-11-23</div>
                </div>
                <span class="status">待审核</span>
            </div>
            <div class="my-news-item status1">
                <div class="row">
                    <span class="category">#妇科</span>
                    <span class="title">天灸三伏貼增強免疫力 攜手對抗新冠肺炎</span>
                </div>
                <div class="row">
                    <div class="tags">
                        <span class="tag">產婦</span>
                        <span class="tag">PCOS</span>
                    </div>
                    <div class="time">2020-11-23</div>
                </div>
                <span class="status">正常</span>
            </div>
            <div class="my-news-item status2">
                <div class="row">
                    <span class="category">#妇科</span>
                    <span class="title">天灸三伏貼增強免疫力 攜手對抗新冠肺炎</span>
                </div>
                <div class="row">
                    <div class="tags">
                        <span class="tag">產婦</span>
                        <span class="tag">PCOS</span>
                    </div>
                    <div class="time">2020-11-23</div>
                </div>
                <span class="status">推薦</span>
            </div>
            <div class="my-news-item status1">
                <div class="row">
                    <span class="category">#妇科</span>
                    <span class="title">天灸三伏貼增強免疫力 攜手對抗新冠肺炎</span>
                </div>
                <div class="row">
                    <div class="tags">
                        <span class="tag">產婦</span>
                        <span class="tag">PCOS</span>
                    </div>
                    <div class="time">2020-11-23</div>
                </div>
                <span class="status">正常</span>
            </div>
            <div class="my-news-item status1">
                <div class="row">
                    <span class="category">#妇科</span>
                    <span class="title">天灸三伏貼增強免疫力 攜手對抗新冠肺炎</span>
                </div>
                <div class="row">
                    <div class="tags">
                        <span class="tag">產婦</span>
                        <span class="tag">PCOS</span>
                    </div>
                    <div class="time">2020-11-23</div>
                </div>
                <span class="status">正常</span>
            </div>
            <div class="my-news-item status1">
                <div class="row">
                    <span class="category">#妇科</span>
                    <span class="title">天灸三伏貼增強免疫力 攜手對抗新冠肺炎</span>
                </div>
                <div class="row">
                    <div class="tags">
                        <span class="tag">產婦</span>
                        <span class="tag">PCOS</span>
                    </div>
                    <div class="time">2020-11-23</div>
                </div>
                <span class="status">正常</span>
            </div>
        </div>
		<div class="split-pages-container">
            <ol class="split-pages">
                <li class="split-page current">1</li>
                <li class="split-page">2</li>
                <li class="split-page">3</li>
                <li class="split-page">4</li>
                <li class="split-page more">…</li>
                <li class="split-page">12</li>
            </ol>
            <div class="btn-page-prev">上一頁</div>
            <div class="btn-page-next">下一頁</div>
        </div>
    </div>
</template>

<script>
	export default {
		mounted: function(){
		},
		data() {
            return {
            }
        },
        methods: {
        }
	};
</script>

<style lang="scss" scoped>
    #my-news {
        position: relative;
        padding-bottom: 60px;
    }

    /* 我的资讯 start */
    .my-news-list {
        display: flex;
        flex-direction: column;
        padding: 10px 30px;   
    }

    .my-news-list > .my-news-item {
        display: flex;
        position: relative;
        flex-direction: column;
        border-bottom: 1px solid #e8e8e8;
        padding: 10px 0px 16px 0px;
    }

    .my-news-list > .my-news-item .row {
        display: flex;
        flex-direction: row;
    }

    .my-news-list > .my-news-item .category,
    .my-news-list > .my-news-item .title {
        color: #231F20;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
    }

    .my-news-list > .my-news-item .category {
        color: #FF8000;
        margin-right: 10px;
    }

    .my-news-list > .my-news-item .tags {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    }

    .my-news-list > .my-news-item .tags > .tag {
        height: 24px;
        line-height: 24px;
        border: 1px solid transparent;
        padding: 0px 8px;
        background-color: #EFF7F8;
        box-sizing: border-box;
        color: #36C4D0;
        font-size: 16px;
        font-weight: bold;
        margin-right: 16px;
        border-radius: 4px;
        cursor: pointer;
    }

    .my-news-list > .my-news-item .tags > .tag:hover {
        border-color: #36C4D0;
    }

    .my-news-list > .my-news-item .time {
        color: #969696;
        line-height: 24px;
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        margin-top: 15px;
    }

    .my-news-list > .my-news-item .status {
        width: 66px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #2A8737;
        background-color: #C5E6C7;
        border-radius: 12px;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .my-news-list > .my-news-item.status0 .status {
        color: #FF8000;
        background-color: #FFE3D5;
    }

    .my-news-list > .my-news-item.status2 .status {
        color: #EE1B23;
        background-color: #FFDCDD;
    }
    /* 我的资讯 end */

	.split-pages-container {
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: flex-end;
	}

	.split-pages-container > .split-pages {
		display: flex;
		flex-direction: row;
	}

	.split-pages-container > .split-pages > .split-page {
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		font-weight: 400;
		margin-right: 20px;
		background-color: #DDDDDD;
		cursor: pointer;
	}

	.split-pages-container > .split-pages > .split-page.more {
		line-height: 16px;
	}

	.split-pages-container > .split-pages > .split-page:hover,
	.split-pages-container > .split-pages > .split-page.current {
		color: #fff;
		background-color: #36C4D0;
		font-weight: bold;
	}

	.split-pages-container > .btn-page-prev,
	.split-pages-container > .btn-page-next {
		height: 20px;
		width: 56px;
		text-align: center;
		line-height: 20px;
		font-weight: 300;
		color: #636363;
		background-color: #DDDDDD;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.split-pages-container > .btn-page-next {
		margin-left: 24px;
		color: #231F20;
	}
</style>
